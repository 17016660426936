import {useState} from "react";
import Slider from "react-slick";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import aiPhoto from "../Assets/ai.jpg";
import bugPhoto from "../Assets/bugTracker.jpg";
import sortingPhoto from "../Assets/sorting.jpg";
import { ImagesearchRoller } from "@mui/icons-material";

const images = [aiPhoto, bugPhoto, sortingPhoto, bugPhoto];

const NextArrow = ({onClick}) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <NavigateNextIcon/>
    </div>
  )
}
const PrevArrow = ({onClick}) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <NavigateBeforeIcon/>
    </div>
  )
}

export default function Carousel() {
  const [imageIndex, setImageIndex] = useState(0)

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300, 
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next)
  };

  return (
    <div className="Carousel">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt="AHHHH"/>
          </div>
      ))}
      </Slider>
    </div>
  );
  
}