import Navbar from "./components/Navbar";
import Header from "./components/Header";
import DownArrow from "./components/DownArrow";
import Projects from "./components/Projects";
import {Contact} from "./components/Contact";
import "./components/Carousel";
import "./App.scss";
import "./styles/Navbar.scss";
import "./styles/Header.scss";
import "./styles/DownArrow.scss";
import "./styles/Projects.scss";
import "./styles/Contact.scss";
import "./styles/Carousel.scss";

function App() {
  return (
    <div className="App">
      <Header/>
      <Projects/>
      <Contact/>
    </div>
  );
}

export default App;
