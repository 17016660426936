import React from 'react'
import EmailIcon from "@material-ui/icons/Mail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
function Header() {
    return (
        <div class = "header">
            <h1 class = "header__title"> Hi I'm Rachel. </h1>
            <p class = "header__description"> I'm a software engineer with a love for problem solving,
                an artist with a mathematical way of thinking. And an advocate
                for inclusive design.
            </p>    
            <button class = "header__about"> More about me </button>

        </div>
    )
}

export default Header