import React, { useRef } from 'react';
import SendIcon from "@mui/icons-material/Send";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_5f6k1yc", "template_u3v7f8p", form.current, "KqmdwO3URUK88VarU")
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  };
  return (
            <form className = "contact" ref={form} onSubmit={sendEmail}>
              <h2>Contact Me</h2> 
              <div>
                <input
                  type="text"
                  required placeholder=" "
                  name="user_name"
                />
                <label for = "user_name"> Name </label> 

              </div>
              <div> 
                <input
                  type="email"
                  required placeholder=" "
                  name="user_email"
                />
                <label for = "user_email"> Email </label>      
              </div>
              <div>
                <textarea name = "message" required placeholder = " "> </textarea>
                <label for = "message"> Message </label>
              </div>
              <div>
                <button type = "submit"> 
                  <SendIcon/> <h3> Send Email </h3> 
                </button>
              </div>
             
          </form>
  );
};
