import React, {useState} from 'react'
import TwitterIcon from "@mui/icons-material/Twitter"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import AlarmIcon from "@mui/icons-material/Alarm"
import AdjustIcon from "@mui/icons-material/Adjust"
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Carousel from "../components/Carousel.js"
function Project(props) {

  return (
    <div class = "project"> 
     
            <div class = "project__info">
             <div class = "project__info__container">
                    <h3 class = "project__title"> 
                        {props.title}
                    </h3> 
                    <h4 class = "project__type"> 
                        {props.type} 
                    </h4>
                    <p class = "project__description"> 
                        {props.description} 
                    </p>
                </div>
                <div class = "project__showCase">
                    <Carousel/>
                        
                </div> 
        
            
                <div class = "project__viewContainer">
                    <a href = {props.live} target = "_blank"> <button class = "project__view"> View Project </button> </a>
                    <a href = {props.github} target = "_blank"> <button class = "project__source"> View GitHub </button> </a>
                </div>
            </div>
    </div>
  )
}

export default Project