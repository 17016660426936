import React from 'react'
import Project from "./Project.js";
import DownArrow from "./DownArrow.js";
import BugTrackerImage from "../Assets/bugTracker.jpg";
import sortingImage from "../Assets/sorting.jpg";
import aiImage from "../Assets/ai.jpg";
function Projects() {
  return (
    <div class = "projects">
        <h2 class = "projects__title"> Projects </h2>
        <DownArrow/>
        <ul class = "projects__ul">
            <li class = "projects__project"> 
                <Project 
                icon = {<img src = {BugTrackerImage}/>}
                type = "Full Stack"  
                title = "Bug Tracker" 
                description = "A full stack web application which allows development teams to catalog, assign, and resolve bugs to improve team efficency"
                live = "https://www.rachel-casey.com/pages/projects/bugTracker"
                github = "https://www.github.com/Rachel-T-Casey"
                /> 
            </li>
            <li class = "projects__project"> 
                <Project 
                icon = {<img src = {sortingImage}/>}
                type = "Front end & Algorithms" 
                title = "Sorting Algorithm Visualizer"
                description = "A tool to visualize and understand sorting algorithms"
                live = "https://www.rachel-casey.com/pages/projects/sortingVisualiser/index.php"
                github = "https://www.github.com/Rachel-T-Casey"
                />
            </li>
            <li class = "projects__project">
                <Project 
                icon = {<img src = {aiImage}/>}
                type = "Machine Learning" 
                title = "Language Predictor"
                description = "A neural network which identifies the language of parsed text    "
                live = "https://www.Rachel-Casey.com/Pages/Projects/languagePredictor"
                github = "https://www.github.com/Rachel-T-Casey/RELU_Language_Identifier"
                />
            </li>
        </ul>
    </div>
  )
}

export default Projects